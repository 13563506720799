<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">banner设置</div>
				<div class="header-button">
					<el-button
						type="primary"
						v-permission="'pc/recruitment-center/banner/add'"
						@click="onCreate"
					>
						新增banner
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="settingRef" @change="onChangeFilter" />
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="columns"
					:data="tableData"
					:params="{ page }"
					autoWidth
					showSelection
				>
					<template #releaseChannel="{ value }">
						<span>{{ mapDict('release_channel', value) }}</span>
					</template>
					<template #maPosition="{ value }">
						<span>{{ mapDict('wechat_ma_position', value) }}</span>
					</template>
					<template #status="{ value }">
						<span>{{ mapDict('banner_disable_status', value) }}</span>
					</template>
					<template #pics="{ value }">
						<span class="click" @click="onView(value)">查看图片</span>
					</template>
					<template #operation="{ row }">
						<span class="click" @click="onDetail(row)">查看详情</span>
						<span
							class="click"
							style="margin-left: 8px"
							@click="onEdit(row)"
							v-permission="'pc/recruitment-center/banner/edit'"
						>
							修改
						</span>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
		<banner-form ref="formRef" @on-refresh="fetchList" />
		<el-image-viewer
			v-if="visible"
			:url-list="srcList"
			hide-on-click-modal
			@close="setView(false)"
		/>
	</div>
</template>

<script setup name="RecruitBanner">
import { inject, ref, onMounted } from 'vue'
import { setting, columns } from './config'
import { useState, useFetch, useDict } from '@/utils/hooks.js'
import { bannerList } from '@/api/recruit'
import BannerForm from './form'

const $message = inject('$message')

const settingRef = ref(setting)
const formRef = ref(null)
const srcList = ref([])

const [params, setParams] = useState({})
const [totalCount, setTotal] = useState(0)
const [tableData, setTable] = useState([])
const [visible, setView] = useState(false)
const { isLoading, fetchData } = useFetch()
const [dicts, setDict] = useState({})

const [page, setPage] = useState({ current: 1, size: 20 })

async function fetchList() {
	const payload = { ...params.value, page: page.value }
	const service = bannerList
	const { data, err } = await fetchData(service, payload)
	if (err) {
		$message.error(err.message)
	} else {
		setTable(data.records)
		setTotal(data.total)
	}
}

function onSizeChange(size) {
	setPage({ current: 1, size })
	fetchList()
}
function onPageChange(current) {
	const { size } = page.value
	setPage({ current, size })
	fetchList()
}

function onChangeFilter(event) {
	setParams(event)
	fetchList()
}
async function fetchDict() {
	const dict = await useDict(['release_channel', 'wechat_ma_position', 'banner_disable_status'])
	setDict(dict)
}
function mapDict(key, value) {
	const e = dicts.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)

	return target ? target.name : '--'
}

function onEdit(row) {
	formRef.value.open('edit', row)
}
function onDetail(row) {
	formRef.value.open('detail', row)
}
function onView(val) {
	console.log('onview', val)
	setView(true)
	srcList.value = val
}
function onCreate() {
	formRef.value.open('create')
}
function init() {
	fetchDict()
	fetchList()
}

onMounted(() => {
	init()
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
}
.click {
	cursor: pointer;
	color: #409eff;
}
</style>
