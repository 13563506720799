<template>
	<el-dialog
		:close-on-click-modal="false"
		:title="formTitleComp"
		v-model="visible"
		:before-close="onClose"
		append-to-body
		modal-append-to-body
		width="650px"
	>
		<div class="form-body" v-loading="isLoading">
			<el-form
				v-if="visible"
				:model="formData"
				:rules="rules"
				class="form-box"
				ref="formRef"
				label-width="120px"
				label-position="right"
			>
				<el-form-item prop="releaseChannel" label="发布渠道">
					<el-select
						:disabled="isDisabled"
						v-model="formData.releaseChannel"
						style="width: 380px"
						placeholder="请选择发布渠道"
					>
						<el-option
							v-for="item in dicts.release_channel"
							:key="item.code"
							:label="item.name"
							:value="item.code * 1"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="maPosition" label="小程序位置">
					<el-select
						v-model="formData.maPosition"
						:disabled="isDisabled"
						style="width: 380px"
						placeholder="请选择小程序位置"
					>
						<el-option
							v-for="item in dicts.wechat_ma_position"
							:key="item.code"
							:label="item.name"
							:value="item.code * 1"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="status" label="发布状态" class="form-item">
					<el-radio-group v-model="formData.status">
						<el-radio
							:disabled="isDisabled"
							:label="item.code * 1"
							:key="item.key"
							v-for="item in dicts.banner_disable_status"
						>
							{{ item.name }}
						</el-radio>
					</el-radio-group>
				</el-form-item>
				<template v-if="formData.details">
					<div v-for="(item, index) in formData.details" :key="index" class="reward-border">
						<el-form-item
							:prop="`details[${index}].pic`"
							:rules="rules['details.pic']"
							:label="picName(index)"
						>
							<yi-file-uploader
								:disabled="isDisabled"
								v-model="item.pic"
								type="file"
								source-type="image"
								product-type="renrenjia"
								accept=".jpg,.jpeg,.png,.xlsx,.xls,.pdf,.zip,.rar,.pptx,.docx"
								:max="1"
								:uploader-size="15"
							/>
							<p style="padding: 0; margin: 0; line-height: 1em">{{ picTip }}</p>
						</el-form-item>
						<el-form-item prop="title" label="banner标题">
							<el-input
								:disabled="isDisabled"
								placeholder="请输入banner标题"
								v-model="formData.details[index].title"
								style="width: 380px"
								maxlength="10"
							/>
						</el-form-item>
						<el-form-item
							:prop="`details[${index}].jumpType`"
							:rules="rules['details.jumpType']"
							label="地址类型"
							class="form-item"
						>
							<el-radio-group v-model="formData.details[index].jumpType">
								<el-radio
									v-for="t in dicts.banner_jump_type"
									:disabled="isDisabled"
									:label="t.code * 1"
									:key="t.key"
								>
									{{ t.name }}
								</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item
							:prop="`details[${index}].jumpPath`"
							:rules="rules['details.jumpPath']"
							label="跳转地址"
							class="form-item"
						>
							<el-input
								:disabled="isDisabled"
								v-model="formData.details[index].jumpPath"
								style="width: 380px"
								placeholder="请输入跳转地址"
							/>
						</el-form-item>
						<el-alert
							:closable="false"
							title="*请注意，小程序地址只能跳转所属小程序的页面"
							type="warning"
						></el-alert>
						<div class="right-btn">
							<el-button :disabled="index == 0 || isDisabled" circle @click="onMoveUp(index)">
								<el-icon :size="16"><Top /></el-icon>
							</el-button>

							<el-button
								:disabled="index == formData.details.length - 1 || isDisabled"
								circle
								@click="onMoveDown(index)"
							>
								<el-icon :size="16"><Bottom /></el-icon>
							</el-button>
							<el-button
								:disabled="formData.details.length <= 1 || isDisabled"
								circle
								type="danger"
								@click="onDeleteBanner(index)"
							>
								<el-icon :size="16"><Delete /></el-icon>
							</el-button>
						</div>
					</div>
				</template>
				<div class="btn-add">
					<el-button
						v-if="
							!isDisabled &&
							formData.details.length < 5 &&
							(formData.maPosition || formData.maPosition === '0')
						"
						type="primary"
						@click="onAddBanner()"
					>
						<el-icon :size="16"><Plus /></el-icon>
						增加
					</el-button>
				</div>
			</el-form>
		</div>
		<div class="button-list">
			<el-button @click="onClose">取消</el-button>
			<el-button v-if="type !== 'detail'" :loading="isLoading" type="primary" @click="onSubmit">
				保存
			</el-button>
		</div>
	</el-dialog>
</template>
<script setup>
import { defineExpose, defineEmits, computed, inject, ref } from 'vue'
import { ElMessageBox } from 'element-plus'
import { Top, Bottom, Delete, Plus } from '@element-plus/icons-vue'
import { rules } from './config'
import { bannerDetail, createBanner, editBanner } from '@/api/recruit.js'
import { useState, useDict, useFetch } from '@/utils/hooks.js'

const emit = defineEmits(['on-refresh'])
const $message = inject('$message')
const formRef = ref(null)
const [type, setType] = useState('create')
const [visible, setVisible] = useState(false)
const [formData, setForm] = useState({ details: [] })
const [dicts, setDicts] = useState({})
const [isDisabled, setDisabled] = useState(false)
const { isLoading, fetchData } = useFetch()
const formTitleComp = computed(() => {
	switch (type.value) {
		case 'create':
			return '新增Banner'
		case 'edit':
			return '修改Banner'
		case 'delete':
			return '删除Banner'
		default:
			return '查看Banner'
	}
})
const picTip = computed(() => {
	const cur = dicts.value.wechat_ma_position.find(item => {
		return item.code * 1 === formData.value.maPosition
	})
	return `（建议尺寸:${cur && cur.remark ? cur.remark : '暂无信息'}）`
})
fetchDict()
function open(type, item) {
	setForm({
		details: [{}],
	})
	setType(type)
	setDisabled(type === 'detail')
	if (type !== 'create') {
		const { bannerId } = item
		fetchDetail(bannerId)
	}
	setVisible(true)
}
function onClose() {
	setVisible(false)
}

function onAddBanner() {
	formData.value.details.push({})
}
async function fetchDetail(bannerId) {
	const { data, err } = await fetchData(bannerDetail, { bannerId })
	if (err) $message.error(err.message)
	console.log('banner详情', data)
	data.details.forEach(detail => (detail.pic = [detail.pic]))
	setForm(data)
}
async function fetchDict() {
	const dict = await useDict([
		'banner_jump_type',
		'release_channel',
		'banner_disable_status',
		'wechat_ma_position',
	])
	setDicts(dict)
}
function picName(index) {
	return `banner图片${index + 1}`
}
function onMoveUp(index) {
	const details = formData.value.details
	const prevData = copyJson(details[index - 1])
	const thisData = copyJson(details[index])
	details[index] = prevData
	details[index - 1] = thisData
	detailListRefresh(details, index, index - 1)
}
// 向下移动
function onMoveDown(index) {
	const details = formData.value.details
	const nextData = copyJson(details[index + 1])
	const thisData = copyJson(details[index])
	details[index] = nextData
	details[index + 1] = thisData
	details(details, index, index + 1)
}
// 刷新列表数据
function detailListRefresh(details, index1, index2) {
	details[index1].refresh = true
	details[index2].refresh = true
	formData.value.details = [...details]
	setTimeout(() => {
		details[index1].refresh = false
		details[index2].refresh = false
		formData.value.details = [...details]
	})
}
function copyJson(data) {
	return JSON.parse(JSON.stringify(data))
}
function onDeleteBanner(index) {
	ElMessageBox.confirm('确定要删除吗?', '提示', {
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		type: 'warning',
	}).then(() => {
		formData.value.details.map(item => {
			item.refresh = true
		})
		formData.value.details.splice(index, 1)
		setTimeout(() => {
			formData.value.details.map(item => {
				item.refresh = false
			})
			formData.value.details = [...formData.value.details]
		})
	})
}

async function onSubmit() {
	await formRef.value.validate()
	const payload = JSON.parse(JSON.stringify(formData.value))
	const service = type.value === 'create' ? createBanner : editBanner
	payload.details.forEach(detail => (Array.isArray(detail.pic) ? (detail.pic = detail.pic[0]) : ''))

	const { err } = await fetchData(service, payload)
	console.log('formData', payload)
	if (err) return $message.error(err.message)
	$message.success('操作成功')
	emit('on-refresh')
	onClose()
}
defineExpose({ open })
</script>

<style scoped lang="less">
@import '@/styles/index';
.button-list {
	margin-top: 50px;
	.flexible(row,center, flex-end);
}
.form-body {
	width: 100%;
	box-sizing: border-box;
	.flexible(column, center, center);
	.form-box {
		width: 550px;
	}
	.reward-border {
		position: relative;
		border: 1px dashed #c0c4cc;
		padding-top: 20px;
		margin-bottom: 10px;
		.right-btn {
			position: absolute;
			right: 10px;
			top: 10px;
		}
	}
	.btn-add {
		text-align: right;
	}
	.bannerImg {
		max-width: 200px;
		max-height: 100px;
		cursor: pointer;
	}
}
</style>
