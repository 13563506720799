export const columns = [
	{
		prop: 'releaseChannel',
		label: '渠道',
	},
	{
		prop: 'maPosition',
		label: '位置',
	},
	{
		prop: 'status',
		label: '状态',
	},
	{
		prop: 'pics',
		label: 'banner图片',
	},
	{
		prop: 'updateTime',
		label: '更新时间',
		width: 220,
	},
	{
		prop: 'updateUser',
		label: '更新人',
	},
	{
		prop: 'operation',
		label: '操作',
		width: '140px',
		fixed: 'right',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			placeholder: '请选择渠道',
			label: '渠道',
			attr: 'releaseChannel',
			type: 'select',
			option: [],
			dictKey: 'release_channel',
		},
		{
			placeholder: '请选择位置',
			label: '位置',
			attr: 'maPosition',
			type: 'select',
			option: [],
			dictKey: 'wechat_ma_position',
		},
		{
			placeholder: '请选择状态',
			label: '状态',
			attr: 'status',
			type: 'select',
			option: [],
			dictKey: 'banner_disable_status',
		},
	],
}

export const rules = {
	releaseChannel: [
		{
			required: true,
			message: '请选择发布渠道',
			trigger: 'change',
		},
	],
	maPosition: [
		{
			required: true,
			message: '请选择小程序位置',
			trigger: 'change',
		},
	],
	status: [
		{
			required: true,
			message: '请选择发布状态',
			trigger: 'change',
		},
	],
	'details.pic': [
		{
			required: true,
			message: '请上传banner图片',
			trigger: 'change',
		},
	],
	// 'details.jumpPath': [
	// 	{
	// 		required: true,
	// 		message: '请输入跳转地址',
	// 		trigger: 'blur',
	// 	},
	// ],
	// 'details.jumpType': [
	// 	{
	// 		required: true,
	// 		message: '请选择地址类型',
	// 		trigger: 'change',
	// 	},
	// ],
}
