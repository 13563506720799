import request from '@/http'

// 报名司机统计
export function driverStat(data) {
	return request({
		url: `/suf4-operation-service/driver/clue/page/statistics`,
		method: 'post',
		data,
	})
}

// 报名司机列表
export function applyDriverList(data) {
	return request({
		url: `/suf4-operation-service/driver/clue/page`,
		method: 'post',
		data,
	})
}

// 报名分配
export function assignDriver(data) {
	return request({
		url: `/suf4-operation-service/driver/clue/assign`,
		method: 'post',
		data,
	})
}

// 报名-城市列表(10-维修厂, 20-城市)
export function applyCityList(type) {
	return request({
		url: `/suf4-user-service/userDataPermission/get/data/permission?type=${type}`,
		method: 'get',
	})
}

// 分配-跟进人列表
export function followerList(suffix, data = []) {
	return request({
		url: `/suf4-user-service/userDataPermission/listByRoleCode/${suffix}`,
		method: 'post',
		data,
	})
}

// 跟进-已跟进列表
export function followUpList(driverClueId) {
	return request({
		url: `/suf4-operation-service/driver/clue/followUp/list?driverClueId=${driverClueId}`,
		method: 'get',
	})
}

// 新增跟进
export function submitFollowUp(data) {
	return request({
		url: `/suf4-operation-service/driver/clue/followUp/submit`,
		method: 'post',
		data,
	})
}

// 编辑跟进
export function editFollowUp(data) {
	return request({
		url: `/suf4-operation-service/driver/clue/followUp/edit`,
		method: 'post',
		data,
	})
}
// 删除跟进
export function deleteFollowUp(followUpId) {
	return request({
		url: `/suf4-operation-service/driver/clue/followUp/delete?followUpId=${followUpId}`,
		method: 'post',
	})
}
// 终止跟进
export function terminateFollowUp(data) {
	return request({
		url: `/suf4-operation-service/driver/clue/followUp/terminate`,
		method: 'post',
		data,
	})
}

// banner列表
export function bannerList(data) {
	return request({
		url: `/suf4-operation-service/banner/page`,
		method: 'post',
		data,
	})
}

// banner详情
export function bannerDetail(params) {
	return request({
		url: `/suf4-operation-service/banner/detail`,
		method: 'get',
		params,
	})
}

// banner新增
export function createBanner(data) {
	return request({
		url: `/suf4-operation-service/banner/add`,
		method: 'post',
		data,
	})
}

// banner编辑
export function editBanner(data) {
	return request({
		url: `/suf4-operation-service/banner/edit`,
		method: 'post',
		data,
	})
}
